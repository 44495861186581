/* global ProtoExtends JSX */
import React, { forwardRef } from "react";
import clsx from "clsx";
import isFunction from "lodash/isFunction";
import isNil from "lodash/isNil";

import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import FormHelperText from "@material-ui/core/FormHelperText";
import Select, { SelectProps } from "@material-ui/core/Select";

import useStyles from "./styles";
import Typography from "@ui-kit/Typography";
import { useViewport } from "@hooks/useViewport";

export interface SelectFieldOption {
  [key: string]: any;
  id: string | number | null;
}

export type SelectComponentProps<T = SelectFieldOption> = ProtoExtends<
  SelectProps,
  {
    items: T[];
    onChange?: (
      event: React.ChangeEvent<HTMLSelectElement> & {
        value: T;
      },
    ) => void;
    labelProp: string;
    formControlClasses?: Partial<{
      root: string;
    }>;
    label?: string;
    hideFilledLabel?: boolean;
    allowEmpty?: boolean;
    emptyLabel?: string;
    error?: any;
  }
>;

const SelectComponent = forwardRef(
  <T extends SelectFieldOption>(
    {
      items,
      onChange,
      labelProp,
      allowEmpty = false,
      emptyLabel = "---",
      hideFilledLabel = false,
      label,
      value,
      formControlClasses,
      error,
      fullWidth = true,
      required,
      name,
      ...props
    }: React.PropsWithChildren<SelectComponentProps<T>>,
    ref: SelectProps["ref"],
  ) => {
    const { isMobile } = useViewport();
    const classes = useStyles();

    const handleChange: SelectProps["onChange"] = (event) => {
      const { value } = event.target;

      if (!isFunction(onChange)) {
        return;
      }

      let i = items.find((i) => i.id === (value as SelectFieldOption["id"]));

      if (!i) {
        if (allowEmpty && !value) {
          i = { id: null } as T;
        } else {
          return;
        }
      }

      onChange({
        ...(event as React.ChangeEvent<HTMLSelectElement>),
        value: i,
      });
    };

    function renderItems() {
      return [
        allowEmpty ? (
          <MenuItem value="" key="empty-option" data-test="empty-option">
            {emptyLabel}
          </MenuItem>
        ) : null,
        ...items.map((item) => (
          <MenuItem
            value={item.id === null ? "" : item.id}
            key={item.id === null ? "" : item.id}
            data-test={item[labelProp]}
            disabled={item.disabled}
          >
            {item[labelProp]}
          </MenuItem>
        )),
      ].filter(Boolean);
    }

    function renderNativeItems() {
      const hasEmptyItem = items.some((i) => isNil(i.id));
      console.log(items);

      return [
        allowEmpty ? (
          <option value="" key="empty-option" data-test="empty-option">
            {emptyLabel}
          </option>
        ) : (
          !hasEmptyItem && <option hidden></option>
        ),
        ...items.map((item) => (
          <option
            value={item.id ?? ""}
            key={item.id ?? ""}
            disabled={item.disabled}
          >
            {item[labelProp]}
          </option>
        )),
      ].filter(Boolean);
    }

    function renderLabel() {
      if (!label) {
        return null;
      }

      if (hideFilledLabel && Boolean(value)) {
        return null;
      }

      return (
        <InputLabel>
          <Typography variant="inherit" required={required}>
            {label}
          </Typography>
        </InputLabel>
      );
    }

    return (
      <FormControl
        margin="normal"
        fullWidth={fullWidth}
        classes={{ ...formControlClasses }}
        error={Boolean(error)}
        className={clsx({
          "form-error": !!error,
        })}
      >
        {renderLabel()}
        <Select
          data-test={name ? `${name}-select` : undefined}
          {...props}
          name={name}
          native={isMobile}
          onChange={handleChange}
          value={value}
          classes={{
            ...props.classes,
            icon: classes.icon,
          }}
          ref={ref}
        >
          {isMobile ? renderNativeItems() : renderItems()}
        </Select>

        {Boolean(error) && (
          <FormHelperText className="error">{error}</FormHelperText>
        )}
      </FormControl>
    );
  },
);

SelectComponent.displayName = "Select";

export default SelectComponent as <T extends SelectFieldOption>(
  props: React.PropsWithChildren<SelectComponentProps<T>>,
) => JSX.Element;
export { ControlledSelectField } from "./ControlledSelectField";
