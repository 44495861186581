import { RegisterOptions } from "react-hook-form";

export const INVALID_PHONE_MESSAGE = "Invalid phone field";
export const INVALID_URL_MESSAGE = "Invalid url field";

export const phoneNumber = (): {
  validate: (value: string) => boolean | string;
} => ({
  validate: (value: string) =>
    !value.length || value.length === 10 || INVALID_PHONE_MESSAGE,
});

export const minLength = (
  length: number,
): {
  minLength: {
    message: string;
    value: number;
  };
} => ({
  minLength: {
    message: `Must be at least ${length} characters`,
    value: length,
  },
});

export const maxLength = (
  length: number,
): {
  maxLength: {
    message: string;
    value: number;
  };
} => ({
  maxLength: {
    message: `Must be less than ${length} characters`,
    value: length,
  },
});

export const length = (length: number): RegisterOptions => ({
  validate: (value: string) =>
    value.length === length || `Must be ${length} characters`,
});

export const required = (): { required: string } => ({
  required: "Field is required",
});

export const email = (): {
  pattern: {
    message: string;
    value: RegExp;
  };
} => ({
  pattern: {
    message: "Invalid email address",
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,10}$/i,
  },
});

export const min = (
  value: number,
  message = `Min value: ${value}`,
): {
  min: {
    message: string;
    value: number;
  };
} => ({
  min: {
    message,
    value,
  },
});

export const max = (
  value: number,
  message = `Max value: ${value}`,
): {
  max: {
    message: string;
    value: number;
  };
} => ({
  max: {
    message,
    value,
  },
});
