import { Models } from "@services/api";

export const ROLES = [
  { id: Models.UserRole.CorporateOffice, name: "Corporate office" },
  { id: Models.UserRole.RegionalManager, name: "Regional manager" },
  { id: Models.UserRole.Owner, name: "Facility owner" },
  { id: Models.UserRole.FacilityDirector, name: "Facility director" },
  { id: Models.UserRole.AssistantDirector, name: "Assistant director" },
  { id: Models.UserRole.MarketingOfficer, name: "Marketing officer" },
  { id: Models.UserRole.AddmissionsOffice, name: "Admissions office" },
  { id: Models.UserRole.Principal, name: "Principal" },
  { id: Models.UserRole.Other, name: "Other" },
];
