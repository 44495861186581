import React, { forwardRef, useMemo } from "react";
import get from "lodash/get";
import {
  UseControllerOptions,
  Controller,
  useFormContext,
} from "react-hook-form";

import SelectField, { SelectFieldOption } from "..";
import {
  ControlledSelectFieldProps,
  ControlledSelectFieldComponent,
} from "./types";
import { t } from "@i18n";

export const ControlledSelectField = forwardRef(
  <T extends SelectFieldOption>(
    {
      name,
      rules,
      defaultValue,
      required,
      onChange: onChangeProp,
      ...props
    }: ControlledSelectFieldProps<T>,
    ref: ControlledSelectFieldProps<T>["ref"],
  ) => {
    const { control, errors } = useFormContext();
    const error = props.error || get(errors, name)?.message;

    const rul: UseControllerOptions["rules"] = useMemo(() => {
      const rul: UseControllerOptions["rules"] = rules || {};

      if (required) {
        rul.required = t("common.fieldRequired");
      }

      return rul;
    }, [rules, required]);

    return (
      <Controller
        render={({ onChange, ...controllerProps }) => (
          <SelectField
            {...controllerProps}
            {...props}
            error={error}
            ref={ref}
            onChange={(event) => {
              onChangeProp?.(event);
              onChange(event);
            }}
          />
        )}
        defaultValue={defaultValue || ""}
        rules={rul}
        name={name}
        control={control}
      />
    );
  },
) as ControlledSelectFieldComponent;
