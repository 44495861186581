import { createUseStyles } from "react-jss";

export default createUseStyles(
  {
    icon: {
      fontSize: "2rem",
      top: "calc(50% - 16px)",
    },
  },
  { name: "CustomSelect" },
);
