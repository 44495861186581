import React, { useCallback, forwardRef, useState, useEffect } from "react";
import MaskedInput from "react-text-mask";
import clsx from "clsx";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import InputAdornment from "@material-ui/core/InputAdornment";
import ArrowDropDownOutlined from "@material-ui/icons/ArrowDropDownOutlined";

import { COUNTRIES, countriesMap } from "./countries";
import { InputFieldProps, InputField } from "../InputFields/Input";
import { createCss } from "./styles";
import { Models } from "@services/api";

export interface Phone {
  country: Models.Countries;
  number: string;
}

export type PhoneInputState = [Phone | null, Phone];

export type PhoneInputProps = ProtoExtends<
  InputFieldProps,
  {
    onChange?: (state: PhoneInputState) => void;
    value?: Phone;
    defaultCountry?: Models.Countries;
    disableCountriesSelect?: boolean;
  }
>;

export const PhoneInput = forwardRef(
  (
    {
      onChange,
      defaultCountry,
      value,
      disableCountriesSelect,
      ...props
    }: PhoneInputProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const css = createCss();
    const [menuAnchorElement, setMenuAnchorElement] =
      useState<HTMLElement | null>(null);
    const selectedCountry =
      value?.country || defaultCountry || Models.Countries.Us;

    useEffect(() => {
      if (!value?.country) {
        onChange?.([
          null,
          {
            country: selectedCountry,
            number: value?.number || "",
          },
        ]);
      }
    }, []);

    const handleFocus = useCallback(
      (event: React.FocusEvent<HTMLDivElement & HTMLInputElement>) => {
        const { target } = event;
        const index = target.value.indexOf("_");

        if (index !== -1) {
          setTimeout(() => {
            target.setSelectionRange(index, index);
          }, 100);
        }
      },
      [],
    );

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        const number = event.target.value.replace(/\D/g, "");
        let value: Phone | null = null;

        if (Boolean(number) && Boolean(selectCountry)) {
          value = {
            country: selectedCountry,
            number,
          };
        }

        onChange?.([
          value,
          {
            country: selectedCountry,
            number,
          },
        ]);
      },
      [onChange, selectedCountry],
    );

    const TextMaskCustom = useCallback(
      (props: any) => {
        const { inputRef, ...other } = props;

        return (
          <MaskedInput
            {...other}
            ref={(ref: any) => {
              inputRef(ref ? ref.inputElement : null);
            }}
            mask={countriesMap[selectedCountry].mask}
            placeholderChar={"_"}
          />
        );
      },
      [selectedCountry],
    );

    const selectCountry = (id: Models.Countries) => {
      setMenuAnchorElement(null);

      if (selectedCountry === id) {
        return;
      }

      onChange?.([
        null,
        {
          country: id,
          number: "",
        },
      ]);
    };

    const renderCountriesMenu = () => {
      return (
        <Menu
          id="countries-menu"
          anchorEl={menuAnchorElement}
          open={Boolean(menuAnchorElement)}
          onClose={() => setMenuAnchorElement(null)}
          data-test="countries-menu"
        >
          {COUNTRIES.map((c) => (
            <MenuItem onClick={() => selectCountry(c.id)} key={c.id}>
              <div css={css.menuItem}>
                <img src={c.icon} alt={c.id} css={css.menuItemFlag} />
                {c.name}

                <span css={css.menuItemCode}>+{c.code}</span>
              </div>
            </MenuItem>
          ))}
        </Menu>
      );
    };

    return (
      <>
        <InputField
          name="phone"
          type="tel"
          {...props}
          onFocus={handleFocus}
          onChange={handleChange}
          ref={ref}
          value={value?.number || ""}
          inputComponent={TextMaskCustom}
          startAdornment={
            <InputAdornment position="start">
              <div css={css.adornment}>
                <div
                  css={css.menu}
                  onClick={(event) => {
                    if (!disableCountriesSelect) {
                      setMenuAnchorElement(event.currentTarget);
                    }
                  }}
                >
                  <img
                    src={countriesMap[selectedCountry].icon}
                    alt={selectedCountry}
                    css={css.menuFlag}
                    className={clsx({
                      disabled: disableCountriesSelect,
                    })}
                  />
                  {!disableCountriesSelect && <ArrowDropDownOutlined />}
                </div>
                <span>+{countriesMap[selectedCountry].code}</span>
              </div>
            </InputAdornment>
          }
        />
        {renderCountriesMenu()}
      </>
    );
  },
);

PhoneInput.displayName = "PhoneInput";
