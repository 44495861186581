import keyBy from "lodash/keyBy";

import { Models } from "@services/api";
import UsaIcon from "@images/countries/us.svg";
import AusIcon from "@images/countries/aus.svg";

export const COUNTRIES: {
  id: Models.Countries;
  icon: string;
  name: string;
  code: string;
  mask: (string | RegExp)[];
}[] = [
  {
    id: Models.Countries.Us,
    icon: UsaIcon,
    name: "United States",
    code: "1",
    mask: [
      "(",
      /\d/,
      /\d/,
      /\d/,
      ")",
      " ",
      /\d/,
      /\d/,
      /\d/,
      " ",
      "-",
      " ",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
  },
  {
    id: Models.Countries.Aus,
    icon: AusIcon,
    name: "Australia",
    code: "61",
    mask: [
      "(",
      /\d/,
      /\d/,
      ")",
      " ",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
      " ",
      "-",
      " ",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ],
  },
];

export const countriesMap = keyBy(COUNTRIES, "id");
