import React, { forwardRef } from "react";
import { Control, UseControllerOptions, Controller } from "react-hook-form";

import { PhoneInput, PhoneInputProps, Phone } from "./PhoneInput";

interface ControlledPhoneInputProps extends PhoneInputProps {
  name: string;
  control: Control;
  defaultValue?: UseControllerOptions["defaultValue"];
  rules?: UseControllerOptions["rules"];
  required?: boolean;
}

const INVALID_PHONE_MESSAGE = "Invalid phone field";

const phoneValidation = (
  required = false,
): {
  validate: (value: Phone) => boolean | string;
} => ({
  validate: (value: Phone) => {
    if (required && !value?.number) {
      return "Field is required";
    }

    return (
      !value?.number || value.number.length === 10 || INVALID_PHONE_MESSAGE
    );
  },
});

export const ControlledPhoneInput = forwardRef(
  (
    {
      name,
      control,
      rules,
      defaultValue,
      required,
      onChange: onChangeProp,
      ...props
    }: ControlledPhoneInputProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const rul: UseControllerOptions["rules"] = rules || {};

    if (!rul.validate) {
      rul.validate = phoneValidation(required);
    }

    return (
      <Controller
        render={({ onChange, ...controllerProps }) => (
          <PhoneInput
            {...controllerProps}
            {...props}
            ref={ref}
            onChange={(state) => {
              onChangeProp?.(state);
              onChange(state[1]);
            }}
          />
        )}
        defaultValue={defaultValue || null}
        rules={rul}
        name={name}
        control={control}
      />
    );
  },
);
