import { Interpolation } from "@emotion/react";

import { ITheme } from "@theme";
import { Classes } from "./types";

export const createCss = (): Record<Classes, Interpolation<ITheme>> => ({
  flag: {
    position: "relative",
    width: 35,

    "& span": {
      display: "inline-block",
      fontSize: 18,
      height: "100%",
      textAlign: "right",
      width: "100%",
    },
  },

  menuFlag: {
    height: 17,
    width: 20,

    "&.disabled": {
      marginRight: 10,
    },
  },

  menu: (theme) => ({
    display: "flex",
    alignItems: "center",
    paddingLeft: 3,

    [theme.mediaRequests.desktop]: {
      borderRadius: 3,
      cursor: "pointer",

      "&:hover": {
        backgroundColor: "rgba(0, 0, 0, .1)",
      },
    },
  }),

  adornment: {
    display: "flex",
    alignItems: "center",
  },

  menuItem: {
    display: "flex",
    alignItems: "center",
  },

  menuItemFlag: {
    height: 17,
    width: 20,
    marginRight: 5,
  },

  menuItemCode: (theme) => ({
    color: theme.palette.text.secondary,
    paddingLeft: 5,
    fontSize: 13,
  }),
});
