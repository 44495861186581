import React, { useEffect, useState } from "react";
import { Redirect } from "react-router-dom";
import {
  useForm,
  Controller,
  UseFormMethods,
  FieldError,
} from "react-hook-form";

import { Input } from "@ui-kit/InputFields";
import Dialog from "@ui-kit/Dialog";
import { ControlledPhoneInput, PhoneInputState } from "@ui-kit/PhoneInput";
import Select from "@ui-kit/Select";
import { createCss } from "./styles";
import Button from "@ui-kit/Button";
import { useUserStore } from "@store/UserStore";
import { required, INVALID_PHONE_MESSAGE } from "@validators";
import { ServerError } from "@models/common";
import { useNotificationStore } from "@store/NotificationStore";
import { ROLES } from "@constants/user-roles";
import { Models } from "@services/api";
import { useAppStore } from "@store/AppStore";
import { expectServerError } from "@helpers/serverError";

interface ParentProfileProps {
  onClose: () => void;
}

type FormFields = {
  email: string;
  firstName: string;
  lastName: string;
  phone: Models.Phone;
  addressZip: string;
  role: Models.UserRole;
};

const instanceOfFormFields = (field: string) => {
  return (
    field === "email" ||
    field === "firstName" ||
    field === "lastName" ||
    field === "phone" ||
    field === "addressZip"
  );
};

const processErrors = (
  errors: ServerError[],
  setError: UseFormMethods["setError"],
) => {
  let errorHandled = false;

  errors.forEach((e) => {
    const paths = e.source.pointer?.split("/");

    if (!paths) {
      return false;
    }

    if (paths[1] === "phone" && e.code === "validation_length_invalid") {
      setError(paths[1], {
        message: INVALID_PHONE_MESSAGE,
        type: "manual",
      });
      errorHandled = true;
      return;
    }

    if (e.code === "validation_required") {
      setError(paths[1], {
        message: required().required,
        type: "manual",
      });
      errorHandled = true;
    }

    if (instanceOfFormFields(paths[1])) {
      setError(paths[1], {
        message: e.title,
        type: "manual",
      });
      errorHandled = true;
      return;
    }
  });

  return errorHandled;
};

const ParentProfile: React.FC<ParentProfileProps> = ({ onClose }) => {
  const [{ realLocation }] = useAppStore();
  const [{ user }, { updateUser }] = useUserStore();
  const [, { setUnknownErrorNotification, setNotification }] =
    useNotificationStore();
  const { handleSubmit, control, errors, setError, formState, setValue } =
    useForm<FormFields>();
  const [phoneState, setPhoneState] = useState<PhoneInputState | null>(
    user?.phone ? [user.phone, user.phone] : null,
  );
  const css = createCss();

  useEffect(() => {
    setValue("addressZip", "");
  }, [phoneState?.[1]?.country]);

  if (!user) {
    return <Redirect to={realLocation.current} />;
  }

  const submitForm = handleSubmit(async (values) => {
    try {
      await updateUser({
        ...user,
        ...values,
      });

      onClose();
    } catch (e) {
      const errors = expectServerError(e, setUnknownErrorNotification);

      if (!processErrors(errors, setError)) {
        setNotification({
          message: errors[0].title,
          type: "error",
        });
      }
    }
  });

  return (
    <Dialog open onClose={onClose} title="My profile">
      <form data-test="parent-profile" css={css.container}>
        <div>
          <Controller
            render={(controllerProps) => (
              <Input {...controllerProps} disabled label="Email" />
            )}
            name="email"
            defaultValue={user.email}
            control={control}
          />

          {user.account.type === Models.AccountType.Business && (
            <Controller
              render={(controllerProps) => (
                <Select
                  {...controllerProps}
                  items={ROLES}
                  labelProp="name"
                  error={errors.role?.message}
                  label="Admin role"
                />
              )}
              rules={required()}
              defaultValue={user.role || ""}
              name="role"
              control={control}
            />
          )}

          <Controller
            render={(controllerProps) => (
              <Input
                {...controllerProps}
                error={errors.firstName?.message}
                label="First name"
              />
            )}
            defaultValue={user.firstName}
            name="firstName"
            control={control}
          />

          <Controller
            render={(controllerProps) => (
              <Input
                {...controllerProps}
                error={errors.lastName?.message}
                label="Last name"
              />
            )}
            defaultValue={user.lastName}
            name="lastName"
            control={control}
          />

          <Controller
            as={
              <Input
                error={errors.addressZip?.message}
                data-test="zip-field"
                variant="number"
                maxLength={5}
                label="Zip code"
              />
            }
            defaultValue={user.addressZip}
            name="addressZip"
            control={control}
          />

          <ControlledPhoneInput
            error={(errors.phone as FieldError)?.message}
            label="Phone"
            defaultValue={user.phone}
            name="phone"
            control={control}
            onChange={setPhoneState}
          />
        </div>

        <div css={css.footer}>
          <Button
            variant="contained"
            color="primary"
            fullWidth
            size="large"
            data-test="submit-btn"
            onClick={submitForm}
            type="submit"
            loading={formState.isSubmitting}
          >
            Save changes
          </Button>
        </div>
      </form>
    </Dialog>
  );
};

export default ParentProfile;
